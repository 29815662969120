import { useAuth } from "../Hooks/useAuth";
import { n2api } from "./n2api";

const useCampaigns = () => {

  const { token } = useAuth();

  const getRunningCampaigns = (platformName) => {
    return n2api.get(`/${platformName.toLowerCase()}/campaigns?status=RUNNING&childResources=AdSet&childResources=Campaign`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const getCampaign = (platformName, campaignId) => {
    return n2api.get(`/${platformName}/campaigns/${campaignId}&childResources=,`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  return {
    getRunningCampaigns,
    getCampaign
  };
}

export default useCampaigns;