import React, { useEffect } from 'react';
import './App.css';
import Router from './Router';


function App() {

  useEffect(() => {
    if(process.env.REACT_APP_PLAUSIBLE === 'production') {
      const script = document.createElement('script');
      script.defer = true;
      script.setAttribute('data-domain', 'dashboard.n2api.io');
      script.src = 'https://plausible.io/js/script.js';
      document.head.appendChild(script);

      // Optional: Cleanup on component unmount
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);

  return (
    <Router />
  );
}

export default App;
