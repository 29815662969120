import { useAuth } from "../Hooks/useAuth";
import { n2api } from "./n2api";

export const useReporting = () => {

  const { token } = useAuth();

  const getBasicReporting = (platformName) => {
    return n2api.get(`/${platformName.toLowerCase()}/reporting`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      },
      params: {
        start: "2024-01-01",
        end: "2024-02-10",
        granularity: "Day",
        dimensions: "Campaign",
        fields: "Impressions",
      }
    }).then(response => response.data.data).catch(error => console.log(error));
  };

  return {
    getBasicReporting
  };
}
