import React from 'react';
import CampaignsTable from '../../Components/CampaignsTable/Index';
import useCampaigns from '../../Api/useCampaigns';
import { useReporting } from '../../Api/useReporting';
import { useAuth } from '../../Hooks/useAuth';

const Campaigns = () => {

  const { getRunningCampaigns } = useCampaigns();
  const { getBasicReporting } = useReporting();
  const [campaigns, setCampaigns] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [activeAgentCampaigns, setActiveAgentCampaigns] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pinterestCampaigns, setPinterestCampaigns] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [spotifyCampaigns, setSpotifyCampaigns] = React.useState([]);
  const [facebookCampaigns, setFacebookCampaigns] = React.useState([])
  // eslint-disable-next-line no-unused-vars
  const [facebookReporting, setFacebookReporting] = React.useState([]);
  const { token } = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function getCampaigns() {
      setIsLoading(true);
      // getRunningCampaigns('activeagent', token).then(campaigns => {
      //   campaigns.forEach(campaign => campaign.platform = 'Active Agent');
      //   setActiveAgentCampaigns(campaigns)
      // });
      // getRunningCampaigns('pinterest', token).then(campaigns => {
      //   campaigns.forEach(campaign => campaign.platform = 'Pinterest');
      //   setPinterestCampaigns(campaigns)
      // });
      // getRunningCampaigns('spotify', token).then(campaigns => {
      //   campaigns.forEach(campaign => campaign.platform = 'Spotify');
      //   setSpotifyCampaigns(campaigns)
      // });
      getRunningCampaigns('facebook').then(campaigns => {
        campaigns.forEach(campaign => campaign.platform = 'Facebook');
        return(campaigns)
      }).then((campaigns) => {
        getBasicReporting('facebook').then(data => {
          console.log(data);
          setIsLoading(false);
          if(!data){
            setFacebookCampaigns(campaigns.map(campaign => {
              campaign.impressions = 'Error fetching data';
              return campaign;
            }));
          } else {
            setFacebookCampaigns(campaigns.map(campaign => {
              const campaignData = data.data.find(report => report.Campaign === campaign.id);
              if (campaignData) {
                campaign.impressions = campaignData.Impressions;
              }
              return campaign;
            }))
          }
        });
      });
    }
    getCampaigns();
  }, []);

  React.useEffect(() => {
    const campaigns = [...activeAgentCampaigns, ...pinterestCampaigns, ...spotifyCampaigns, ...facebookCampaigns];
    setCampaigns(campaigns);
  }, [activeAgentCampaigns, pinterestCampaigns, spotifyCampaigns, facebookCampaigns]);

  // React.useEffect(() => {
  //   async function getCampaigns() {
  //     const runningActiveAgentCampaigns = getRunningCampaigns('activeagent', token)
  //     const runningPinterestCampaigns = getRunningCampaigns('pinterest', token)
  //     const runningSpotifyCampaigns = getRunningCampaigns('spotify', token)
  //     const runningCampaigns = await Promise.all([runningActiveAgentCampaigns, runningPinterestCampaigns, runningSpotifyCampaigns])
  //     // add platform name to each campaign
  //     runningCampaigns[0].forEach(campaign => campaign.platform = 'Active Agent');
  //     runningCampaigns[1].forEach(campaign => campaign.platform = 'Pinterest');
  //     runningCampaigns[2].forEach(campaign => campaign.platform = 'Spotify');
  //     setCampaigns(runningCampaigns.flat());
  //   }
  //   getCampaigns();
  // }, []);

  return (
    <>
      <CampaignsTable isLoading={isLoading} campaigns={campaigns} />
    </>
  );
}

export default Campaigns;