    import React from 'react';
    import AdSetsTable from '../../Components/AdSetsTable/Index';
    import useAdSets from '../../Api/useAdSets';
    import { useAuth } from '../../Hooks/useAuth';
    
    const AdSets = () => {
    
      const {getRunningAdSets } = useAdSets();
      const [adSets, setAdSets] = React.useState([]);
      // eslint-disable-next-line no-unused-vars
      const [activeAgentAdSets, setActiveAgentAdSets] = React.useState([]);
      const [facebookAdSets, setFacebookAdSets] = React.useState([])
      const { token } = useAuth();
      const [isLoading, setIsLoading] = React.useState(true);
    
      React.useEffect(() => {
        async function getAdSets() {
          // getRunningAdSets('activeagent', token).then(adSets => {
          //   adSets.forEach(adSet => adSet.platform = 'Active Agent');
          //   setActiveAgentAdSets(adSets)
          // });
          setIsLoading(true);
          getRunningAdSets('facebook', token).then(adSets => {
            adSets.forEach(adSet => adSet.platform = 'Facebook');
            setFacebookAdSets(adSets)
            setIsLoading(false);
          });
        }
        getAdSets();
      }, []);
    
      React.useEffect(() => {
        const adSets = [...activeAgentAdSets, ...facebookAdSets];
        setAdSets(adSets);
      }, [activeAgentAdSets, facebookAdSets]);

    
      return (
        <>
          <AdSetsTable adSets={adSets} isLoading={isLoading} />
        </>
      );
    }

export default AdSets